const LOCAL_API = false;

const splitTime = (time) => {
  const H = 300*60*60
  const M = 300*60
  const S = 300
  const F = 12
  let reminder = time % H
  let h = '0' + ((time-reminder) / H)
  h = h.substr(-2)
  time = reminder
  reminder = time % M
  let m = '0' + ((time - reminder ) / M)
  m = m.substr(-2)
  time = reminder
  reminder = time % S
  let s = '0' + ((time-reminder) / S)
  s = s.substr(-2)
  time = reminder
  let f = '0' + Math.round(time / F)
  f = f.substr(-2)
  let times = {
    hour: h,
    min: m,
    second: s,
    frame: f
  }
  return times
}

let DEBUGMODE = false;
let URL = ''
if (LOCAL_API) {
  DEBUGMODE = true;
  URL = 'http://localhost:4000';
} else {
  DEBUGMODE = false;
  URL = 'http://ppa.gabyw.com:4000';
}

const DbConst = {
  BASE_URL: `${URL}`,
  LOGIN: '/login',
  SETTING: '/setting',
  SCHEDULE: '/schedule',
  CHANNELS: '/channels',
  CONNECTIONERROR: 'Connection error. Please check your internet connection',
  DEFSTARTTIME: 6480000,
  DEFENDTIME: 32400000,
  FRAME: 12,
  SEC: 300,
  MIN: 18000,
  HOUR: 1080000,

  logout () {
    localStorage.removeItem('UserToken');
    window.location.replace('/');
  },

  timeToFormatedString(time) {
    let times = splitTime(time);
    let sTime = `${times.hour}:${times.min}:${times.second}:${times.frame}`;
    return sTime;
  },

  timeToString(time) {
    let times = splitTime(time);
    let sTime = `${times.hour}${times.min}${times.second}${times.frame}`;
    return sTime;
  },

  timeStringToTime(value){
    if (value.length > 7) {
      let h
      let m
      let sec
      let frame
      h = parseInt(value.substring(0, 2));
      m = parseInt(value.substring(2, 4));
      sec = parseInt(value.substring(4, 6));
      frame = parseInt(value.substr(-2));
      return h*this.HOUR + m * this.MIN + sec * this.SEC + frame * this.FRAME;
    } else {
      return 0;
    }
  },

  log(...aLog) {
    if (DEBUGMODE) {
      console.log(...aLog);
    }
  }
}

export default DbConst
