import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const classes = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1)
  }
}))

export default function AddressForm (param) {
  const data = param.data.Data.data;
  const valid = param.data.Data.valid;
  const func = param.data.func;
  return (
    <>
      <Typography variant='h6' gutterBottom>
        General settings
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            required
            id='company'
            name='company'
            label='Company name'
            fullWidth
            error={!valid.company}
            autoComplete='on'
            value={data.general.company || ''}
            onChange={e => func.general(e)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id='address1'
            name='address1'
            label='Address line 1'
            fullWidth
            error={!valid.address1}
            autoComplete='on'
            value={data.general.address1 || ''}
            onChange={e => func.general(e)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id='address2'
            name='address2'
            label='Address line 2'
            fullWidth
            autoComplete='on'
            value={data.general.address2 || ''}
            onChange={e => func.general(e)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id='city'
            name='city'
            label='City'
            fullWidth
            error={!valid.city}
            autoComplete='on'
            value={data.general.city || ''}
            onChange={e => func.general(e)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id='state'
            name='state'
            label='State/Province/Region'
            fullWidth
            value={data.general.state || ''}
            onChange={e => func.general(e)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id='zip'
            name='zip'
            label='Zip / Postal code'
            fullWidth
            error={!valid.zip}
            autoComplete='on'
            value={data.general.zip || ''}
            onChange={e => func.general(e)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id='country'
            name='country'
            label='Country'
            fullWidth
            error={!valid.country}
            autoComplete='on'
            value={data.general.country || ''}
            onChange={e => func.general(e)}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant='contained'
            color='primary'
            onClick={e => func.save(e)}
            className={classes.button}
          >
          Save
          </Button>
        </Grid>
      </Grid>
    </>
  )
}
