import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Paper from '@material-ui/core/Paper'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3)
    }
  }
}));

export default function ControlledExpansionPanels() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.layout}>
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>06:00:00:00 Program 1</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
        <Typography>
          06:00:00:00 Junction 1
        </Typography>
        </ExpansionPanelDetails>
        <ExpansionPanelDetails>
        <Typography>
          06:00:00:00 Sponsorship
        </Typography>
        </ExpansionPanelDetails>
        <ExpansionPanelDetails>
        <Typography>
          06:00:01:00 Program part 1
        </Typography>
        </ExpansionPanelDetails>
        <ExpansionPanelDetails>
        <Typography>
          06:32:05:14 Sponsorship
        </Typography>
        </ExpansionPanelDetails>
        <ExpansionPanelDetails>
        <Typography>
          06:32:06:14 Commercial
        </Typography>
        </ExpansionPanelDetails>
        <ExpansionPanelDetails>
        <Typography>
          06:32:36:21 Sponsorship
        </Typography>
        </ExpansionPanelDetails>
        <ExpansionPanelDetails>
        <Typography>
          06:32:37:21 Program part 2
        </Typography>
        </ExpansionPanelDetails>
        <ExpansionPanelDetails>
        <Typography>
          06:58:40:05 Sponsorship
        </Typography>

        </ExpansionPanelDetails>
      </ExpansionPanel>

<ExpansionPanel>
  <ExpansionPanelSummary
    expandIcon={<ExpandMoreIcon />}
    aria-controls="panel2a-content"
    id="panel2a-header"
  >
    <Typography className={classes.heading}>07:00:00:00 Program 2</Typography>
  </ExpansionPanelSummary>
  <ExpansionPanelDetails>
  <Typography>
    07:00:00:00 Junction 1
  </Typography>
  </ExpansionPanelDetails>
  <ExpansionPanelDetails>
  <Typography>
    07:00:00:00 Sponsorship
  </Typography>
  </ExpansionPanelDetails>
  <ExpansionPanelDetails>
  <Typography>
    07:00:01:00 Program part 1
  </Typography>
  </ExpansionPanelDetails>
  <ExpansionPanelDetails>
  <Typography>
    07:32:05:14 Sponsorship
  </Typography>
  </ExpansionPanelDetails>
  <ExpansionPanelDetails>
  <Typography>
    07:32:06:14 Commercial
  </Typography>
  </ExpansionPanelDetails>
  <ExpansionPanelDetails>
  <Typography>
    07:32:36:21 Sponsorship
  </Typography>
  </ExpansionPanelDetails>
  <ExpansionPanelDetails>
  <Typography>
    07:32:37:21 Program part 2
  </Typography>
  </ExpansionPanelDetails>
  <ExpansionPanelDetails>
  <Typography>
    07:58:40:05 Sponsorship
  </Typography>

  </ExpansionPanelDetails>
</ExpansionPanel>

    </div>
  );
}
