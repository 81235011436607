import React, { Component } from 'react';
import D from './../data/dbConst';

class RawPlanner extends Component {
  constructor (props) {
    super(props);
  }

  render () {
    return (
      <div>
        <h1>Day, raw pallaner</h1>
        <p> This function is not available, yet.</p>
      </div>
    )
  }
}

export default RawPlanner
