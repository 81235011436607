import React, { Component } from 'react';
import D from './../data/dbConst';

class User extends Component {
  constructor (props) {
    super(props);
  }

  render () {
    return (
      <div>
        <h1>Users</h1>
        <p> This function is not available, yet.</p>
      </div>
    )
  }
}

export default User
