import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Login from './component/Login';
import MainLayout from './component/MainLayout';
import TooSmall from './stateless/TooSmall';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const MainComponent = () => {
  const matches = useMediaQuery('(min-width:600px)');
  const getToken = localStorage.getItem('UserToken');
  if (!matches) { return (<TooSmall key='toosmall'/>) }
  if (getToken) { return (<MainLayout key='MainLayout'/>)}
  return (<Login key='Login' />)
}

function App() {
  return (
    <Router>
      <Route path='/' component={MainComponent} key='main' />
    </Router>
  );
}

export default App;
