import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))

const Loading = (props) => {
  const classes = useStyles();
return (
  <>
  <Backdrop className={classes.backdrop}  open={props.Fetching} >
    <CircularProgress color="inherit" />
  </Backdrop>
  </>);
}

export default Loading
