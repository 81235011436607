import React, { Component } from 'react';
import SettingsForm from './../stateless/Settings/SettingsForm';
import D from './../data/dbConst';

class Settings extends Component {
  constructor (props) {
    super(props);
    this.state = {
      data: {
        general: {
          company: null,
          address1: null,
          address2: null,
          city: null,
          state: null,
          zip: null,
          country: null
        },
        channels: [],
      },
      editChannel: {
        isEdit: 0,
        isInsert: 0,
        id: null,
        channelName: null,
        channelNameValid: true,
        channelCode: null,
        channelCodeValid: true,
        channelType: 'Linear',
        channelTypeValid: true,
        channelStartTime: '6:00:00:00',
        channelStartTimeValid: true,
        channelEndTime: '30:00:00:00',
        channelEndTimeValid: true,
      },
      valid: {
          company: true,
          address1: true,
          city: true,
          country: true,
          zip: true,
      },
      fetching: false
    };
    this.generalFieldChange = this.generalFieldChange.bind(this);
    this.channelFieldChange = this.channelFieldChange.bind(this);
    this.SaveData = this.SaveData.bind(this);
    this.insertChannel = this.insertChannel.bind(this);
    this.cancelChannelEdit = this.cancelChannelEdit.bind(this);
    this.channelToEdit = this.channelToEdit.bind(this);
    this.SaveChannel = this.SaveChannel.bind(this);
  }

  componentDidMount () {
    D.log(this.state)
    if (this.state.fetching) {
      return 0
    } else {
      this.setState({fetching: true})
    }
    const token = localStorage.getItem('UserToken')
    localStorage.setItem('LastFetch', D.BASE_URL + D.SETTING + '?token=' + token)
    fetch(D.BASE_URL + D.SETTING + '?token=' + token)
      .then(async response => {
        const data = await response.json()

        if (!response.ok) {
          const error = (data && data.message) || response.status
          return Promise.reject(error)
        }
        if (data.token) {
          this.setState({ data: data })
          this.setState({fetching: false})
        } else {
          this.setState({fetching: false})
          D.logout()
        }
      })
      .catch(error => {
        this.setState({ errorMsg: D.CONNECTIONERROR })
        localStorage.setItem('LastError', error)
        this.setState({fetching: false})
        //D.logout()
      })
  }

  generalFieldChange (control) {
    if (this.state.data.general) {
      const { name, value } = control.target
      const p = this.state.data.general
      var someProperty = { ...p }
      someProperty[name] = value
      var newData = this.state.data
      newData.general = someProperty
      this.setState({ data: newData })
    }
  }

  channelFieldChange (control) {
    if (this.state.editChannel) {
      const { name, value } = control.target;
      const p = this.state.editChannel;
      D.log('Change fields', value, control.target, p);
      var someProperty = { ...p };
      someProperty[name] = value;
      var newData = this.state.editChannel;
      newData = someProperty;
      D.log(newData);
      this.setState({ editChannel: newData })
    }
  }

  channelToEdit(id) {
    D.log(id, this.state)
    this.cancelChannelEdit();
    this.state.data.channels.map(item => {
      if (item._id === id) {
        let editChannel = { ...this.state.editChannel }
        if (!this.state.editChannel.isEdit || !this.state.editChannel.isInsert) {
          editChannel.isEdit = 1;
        }
        // copy to edit
        editChannel.id = item._id;
        editChannel.channelName = item.channelName;
        editChannel.channelCode = item.channelCode;
        editChannel.channelType = item.channelType;
        editChannel.channelStartTime = D.timeToString(item.channelStartTime);
        editChannel.channelEndTime = D.timeToString(item.channelEndTime);

        editChannel.channelNameValid = true;
        editChannel.channelCodeValid = true;
        editChannel.channelTypeValid= true
        editChannel.channelStartTimeValid = true;
        editChannel.channelEndTimeValid = true;

        this.setState({ editChannel });
      }
      return 0;
    })
  }

  generalFormValidate() {
    const general = { ...this.state.data.general};
    let valid = { ...this.state.valid };
    let allValid = true;
    // company
    if (general.company == '') {
      valid.company = false;
      allValid = false;
    } else {
      valid.company = true;
    }
    // address1
    if (general.address1 == '') {
      valid.address1 = false;
      allValid = false;
    } else {
      valid.address1 = true;
    }
    // city
    if (general.city == '') {
      valid.city = false;
      allValid = false;
    } else {
      valid.city = true;
    }
    // zip
    if (general.zip == '') {
      valid.zip = false;
      allValid = false;
    } else {
      valid.zip = true;
    }
    // country
    if (general.country == '') {
      valid.country = false;
      allValid = false;
    } else {
      valid.country = true;
    }
    this.setState({valid})
    return allValid;
  }

  channelEditValidate() {
    let editChannel = { ...this.state.editChannel};
    let allValid = true;
    // validate channel name
    if (editChannel.channelName == '') {
      editChannel.channelNameValid = false;
      allValid = false;
    } else {
      editChannel.channelNameValid = true;
    }
    // Validate channel code
    if (editChannel.channelCode == '') {
      editChannel.channelCodeValid = false;
      allValid = false;
    } else {
      editChannel.channelCodeValid = true;
    }
    if (editChannel.channelType != 'Linear' && editChannel.channelType != 'DT') {
      editChannel.channelTypeValid = false;
      allValid = false;
      D.log('editChannel.channelType', editChannel.channelType)
    } else {
      editChannel.channelTypeValid = true;
    }
    // Validate start time
    if (D.timeToString(editChannel.channelStartTime) == 0) {
      editChannel.channelStartTimeValid = false;
      allValid = false;
    } else {
      editChannel.channelStartTimeValid = true;
    }
    // Validate end time
    if (D.timeToString(editChannel.channelEndTime) == 0) {
      editChannel.channelEndTimeValid = false;
      allValid = false;
    } else {
      editChannel.channelEndTimeValid = true;
    }

    if (editChannel.channelStartTimeValid && editChannel.channelEndTimeValid) {
      if (D.timeToString(editChannel.channelStartTime) >= D.timeToString(editChannel.channelEndTime)) {
        editChannel.channelStartTimeValid = false;
        editChannel.channelEndTimeValid = false;
        allValid = false;
      }
    }

    D.log('validator', editChannel, allValid)  ;

    // editChannel.channelCode = item.channelCode;
    // editChannel.channelType = item.channelType;
    // editChannel.channelStartTime = D.timeToString(item.channelStartTime);
    // editChannel.channelEndTime = D.timeToString(item.channelEndTime);
    this.setState({ editChannel })
    return allValid;
  }

  insertChannel () {
    this.cancelChannelEdit()
    if (!this.state.editChannel.isEdit || !this.state.editChannel.isInsert) {
      let editChannel = { ...this.state.editChannel};
      editChannel.isInsert = 1;
      editChannel.id = null;
      editChannel.channelName = '';
      editChannel.channelNameValid = true;
      editChannel.channelCode = '';
      editChannel.channelCodeValid = true;
      editChannel.channelType = '';
      editChannel.channelTypeValid = true;
      editChannel.channelStartTime = D.timeToString(D.DEFSTARTTIME);
      editChannel.channelStartTimeValid = true;
      editChannel.channelEndTime = D.timeToString(D.DEFENDTIME);
      editChannel.channelEndTimeValid = true;

      this.setState({ editChannel });
    }
  }

  cancelChannelEdit () {
    if (this.state.editChannel.isEdit || this.state.editChannel.isInsert) {
      let editChannel = { ...this.state.editChannel} ;
      editChannel.isEdit = 0
      editChannel.isInsert = 0
      this.setState({ editChannel })
    }
  }

  CopyEditToObj(item) {
    let editChannel = {}
    // copy to edit
    editChannel['_id'] = item._id;
    editChannel['channelName'] = this.state.editChannel.channelName;
    editChannel['channelCode'] = this.state.editChannel.channelCode;
    editChannel['channelType'] = this.state.editChannel.channelType;
    editChannel['channelStartTime'] = D.timeStringToTime(this.state.editChannel.channelStartTime);
    editChannel['channelEndTime'] = D.timeStringToTime(this.state.editChannel.channelEndTime);
    return editChannel;
  }
  GetMinChannelID() {
    let id = -1;
    this.state.data.channels.map(item => {
      D.log('GetMinChannelID', item);
      if (item._id <= id) {
        id = item._id - 1;
      }
    })
    return id;
  }
  SaveChannelEdit () {
    let channels = [];
    channels = this.state.data.channels.map(item => {
      if (item._id === this.state.editChannel.id) {
        return this.CopyEditToObj(item);
      } else {
        return item;
      }
    })
    if (this.state.editChannel.isInsert) {
      let tmpChannels = [];
      tmpChannels = [...channels, this.CopyEditToObj({_id: this.GetMinChannelID()})];
      channels = tmpChannels;
    }
    D.log(channels, this.state.data.channels);
    let data = this.state.data;
    data.channels = channels;
    this.setState({data: data})
    D.log(channels, this.state);
  }

  SaveChannel() {
    D.log('SaveChannel clk')
    if (this.state.editChannel.isEdit || this.state.editChannel.isInsert) {
      if (!this.channelEditValidate()) {
        return 0;
      }
      let editChannel = { ...this.state.editChannel} ;
      if (editChannel.isEdit) {
        D.log('Edit', editChannel)
        this.SaveChannelEdit();
      }
      if (editChannel.isInsert) {
        this.SaveChannelEdit();
        D.log('Insert', editChannel)
      }

      editChannel.isEdit = 0
      editChannel.isInsert = 0
      this.setState({ editChannel })
    }
  }

  SaveData () {
    D.log('Save clk', this.state.data)
    if (!this.generalFormValidate()) {
      return 0;
    }
    if (this.state.fetching) {
      return 0
    } else {
      this.setState({fetching: true})
    }
    const token = localStorage.getItem('UserToken')
    const body = {
      general: this.state.data.general,
      channels: this.state.data.channels,
      token: token
    }
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body)
    }
    D.log('save data: ', body);
    fetch(D.BASE_URL + D.SETTING, requestOptions)
      .then(async response => {
        const data = await response.json()

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status
          return Promise.reject(error)
        }

        if (data.token) {
          this.setState({ data: data })
          this.setState({fetching: false})
        } else {
          this.setState({fetching: false})
          D.logout(1)
        }
      })
      .catch(error => {
        console.error('There was an error!', error)
        this.setState({ errorMsg: D.CONNECTIONERROR })
        this.setState({fetching: false})
      })
  }

  render () {
    const func = {
      general: this.generalFieldChange,
      channel: this.channelFieldChange,
      save: this.SaveData,
      saveChannel: this.SaveChannel,
      insertChannel: this.insertChannel,
      cancelChannelEdit: this.cancelChannelEdit,
      channelToEdit: this.channelToEdit,
    }
    return (
      <div>
        <SettingsForm Data={this.state} func={func} />
      </div>
    )
  }
}

export default Settings
