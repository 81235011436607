import React, { Component } from 'react'
import ScheduleForm from './../stateless/Schedule/ScheduleForm'
import D from './../data/dbConst'

class Schedule extends Component {
  constructor (props) {
    super(props)
    this.state = {
      channels: [],
      fetching: true
    }
    this.generalFieldChange = this.generalFieldChange.bind(this)
    this.channelFieldChange = this.channelFieldChange.bind(this)
    this.SaveData = this.SaveData.bind(this)
    this.insertChannel = this.insertChannel.bind(this)
    this.cancelChannelEdit = this.cancelChannelEdit.bind(this)
    this.channelToEdit = this.channelToEdit.bind(this)
  }

  componentDidMount () {
    D.log(this.state)
    const token = localStorage.getItem('UserToken')
    localStorage.setItem('LastFetch', D.BASE_URL + D.CHANNELS + '?token=' + token)
    this.setFetch(true);
    fetch(D.BASE_URL + D.CHANNELS + '?token=' + token)
      .then(async response => {
        const data = await response.json()

        if (!response.ok) {
          const error = (data && data.message) || response.status
          return Promise.reject(error)
        }
        if (data.token) {
          this.setState({ channels: data.channels })
          this.setFetch(false);
        } else {
          this.setFetch(false);
          D.logout()
        }
      })
      .catch(error => {
        this.setState({ errorMsg: D.CONNECTIONERROR })
        localStorage.setItem('LastError', error)
        this.setFetch(false);
      })
  }

  setFetch(value) {
      this.setState({fetching: value});
  }

  generalFieldChange (control) {
    if (this.state.data.general) {
      const { name, value } = control.target
      const p = this.state.data.general
      var someProperty = { ...p }
      someProperty[name] = value
      var newData = this.state.data
      newData.general = someProperty
      this.setState({ data: newData })
    }
  }

  channelFieldChange (control) {
    if (this.state.editChannel) {
      const { name, value } = control.target
      D.log(value)
      const p = this.state.editChannel
      var someProperty = { ...p }
      someProperty[name] = value
      var newData = this.state.editChannel
      newData = someProperty
      this.setState({ editChannel: newData })
    }
  }

  channelToEdit(id) {
    D.log(id, this.state);
    this.cancelChannelEdit();
    this.state.data.channels.map(item => {
      if (item._id === id) {
        let editChannel = { ...this.state.editChannel};
        if (!this.state.editChannel.isEdit || !this.state.editChannel.isInsert) {
          editChannel.isEdit = 1;
        }
        // copy to edit
        editChannel.channelName = item.channelName;
        editChannel.channelCode = item.channelCode;
        editChannel.channelType = item.channelType;
        editChannel.channelStartTime = D.timeToString(item.channelStartTime);
        editChannel.channelEndTime = D.timeToString(item.channelEndTime);
        this.setState({ editChannel });
      }
      return 0;
    })

  }

  insertChannel () {
    this.cancelChannelEdit()
    if (!this.state.editChannel.isEdit || !this.state.editChannel.isInsert) {
      let editChannel = { ...this.state.editChannel}
      editChannel.isInsert = 1
      editChannel.channelName = ''
      editChannel.channelCode = ''
      editChannel.channelType = ''
      editChannel.channelStartTime = D.timeToString(D.DEFSTARTTIME)
      editChannel.channelEndTime = D.timeToString(D.DEFENDTIME)

      this.setState({ editChannel })
    }
  }

  cancelChannelEdit () {
    if (this.state.editChannel.isEdit || this.state.editChannel.isInsert) {
      let editChannel = { ...this.state.editChannel} ;
      editChannel.isEdit = 0
      editChannel.isInsert = 0
      this.setState({ editChannel })
    }
  }

  SaveData (v) {
    D.log('Save clk', this.state.data)
    const token = localStorage.getItem('UserToken')
    const body = {
      general: this.state.data.general,
      token: token
    }
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body)
    }
    fetch(D.BASE_URL + D.SETTING, requestOptions)
      .then(async response => {
        const data = await response.json()

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status
          return Promise.reject(error)
        }

        if (data.token) {
          this.setState({ data: data })
        } else {
          D.logout()
        }
      })
      .catch(error => {
        console.error('There was an error!', error)
        this.setState({ errorMsg: D.CONNECTIONERROR })
      })
  }

  render () {
    const func = {
      save: this.SaveData,
      insertChannel: this.insertChannel,
      cancelChannelEdit: this.cancelChannelEdit,
      channelToEdit: this.channelToEdit,
      channels: this.state.channels,
    }
    return (
      <div>
        <ScheduleForm Data={func} />
      </div>
    )
  }
}

export default Schedule
