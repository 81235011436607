import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import GeneralForm from './GeneralForm';
import ChannelsForm from './ChannelsForm';
import BreakForm from './BreakForm';
import D from './../../data/dbConst';
import Loading from './../../data/Loading';

const useStyles = makeStyles((theme) => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3)
    }
  }
}))

function TabPanel (props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

export default function SettingsForm (props) {
  D.log(props);
  const classes = useStyles()
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <>
      <Loading Fetching={props.Data.fetching} />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <>
            <Paper square>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label='Settings'
                textColor='primary'
                indicatorColor='primary'
                variant='scrollable'
                scrollButtons='auto'
              >
                <Tab label='General settings' />
                <Tab label='Channels' />
                <Tab label='Break settings' />
              </Tabs>
            </Paper>
            <TabPanel value={value} index={0}>
              <GeneralForm data={props} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <ChannelsForm data={props} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <BreakForm data={props} />
            </TabPanel>
          </>
        </Paper>
      </main>
    </>
  )
}
