import React, { Component } from 'react'
import SignIn from './../stateless/Login/SignIn'
import D from './../data/dbConst'

class Login extends Component {
  constructor (props) {
    super(props)
    this.state = {
      name: '',
      password: '',
      errorMsg: '',
      fetching: false
    }
    this.onPasswordChange = this.onPasswordChange.bind(this)
    this.onNameChange = this.onNameChange.bind(this)
    this.login = this.login.bind(this)
  }

  onNameChange (value) {
    D.log('LoginNameChange: ', value)
    this.setState({ name: value })
  }

  onPasswordChange (value) {
    this.setState({ password: value })
  }

  login (e) {
    this.setState({fetching: true});

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ name: this.state.name, password: this.state.password })
    }

    fetch(D.BASE_URL + D.LOGIN, requestOptions)
      .then(async response => {
        const data = await response.json()

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status
          return Promise.reject(error)
        }
        D.log(data)
        if (data.token) {
          this.setState({fetching: false});
          localStorage.setItem('UserToken', data.token)
          window.location.replace('/')
        } else {
          this.setState({fetching: false});
          this.setState({ errorMsg: data.data })
        }
      })
      .catch(error => {
        this.setState({fetching: false});
        console.error('There was an error!', error)
        this.setState({ errorMsg: D.CONNECTIONERROR })
      })
    e.preventDefault()
  }

  render () {
    return (
      <div>
        <SignIn Login={this.login} NameChange={this.onNameChange} PasswordChange={this.onPasswordChange} ErrorMsg={this.state.errorMsg} Fetching={this.state.fetching} />
      </div>
    )
  }
}

export default Login
