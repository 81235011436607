import React from 'react'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import PeopleIcon from '@material-ui/icons/People'
import BallotIcon from '@material-ui/icons/Ballot'
import MenuBookIcon from '@material-ui/icons/MenuBook'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import SettingsIcon from '@material-ui/icons/Settings'
import AirplayIcon from '@material-ui/icons/Airplay'

export function mainListItems (p) {
  return (
    <div>
      <ListItem button onClick={e => p.params.schedule()}>
        <ListItemIcon>
          <BallotIcon />
        </ListItemIcon>
        <ListItemText primary='Schedule' />
      </ListItem>

      <ListItem button onClick={e => p.params.programs()}>
        <ListItemIcon>
          <MenuBookIcon />
        </ListItemIcon>
        <ListItemText primary='Programs' />
      </ListItem>

      <ListItem button onClick={e => p.params.rawPlanner()}>
        <ListItemIcon>
          <AirplayIcon />
        </ListItemIcon>
        <ListItemText primary='Day raw planner' />
      </ListItem>

      <ListItem button onClick={e => p.params.users()}>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary='Users' />
      </ListItem>

      <ListItem button onClick={e => p.params.settings()}>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary='Settings' />
      </ListItem>

      <ListItem button onClick={e => p.params.logoutApp(1)}>
        <ListItemIcon>
          <ExitToAppIcon />
        </ListItemIcon>
        <ListItemText primary='Logout' />
      </ListItem>
    </div>
  )
}
