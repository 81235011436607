import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import CommentIcon from '@material-ui/icons/Comment';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import D from './../../data/dbConst';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  select: {
    minWidth: '100%',
    width: '100%',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '1rem'
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));
// const F = {channnels:true}

const createInsertBtn = (func, classes) => {
  return (
    <>
    <Grid item xs={12} className={classes.buttons}>
      <Button
        variant='contained'
        onClick={e => func.insertChannel()}
        className={classes.button}
      >
      Insert new channel
      </Button>
    </Grid>
    </>
  )
}


function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;
  D.log('NumberFormat', props)

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        if (values.value.length > 7) {
          let h
          let m
          let sec
          let frame
          h = values.value.substring(0, 2);
          (values.value.length > 4) ?
            m = values.value.substring(2, 4) :
            m = '00';
          (values.value.length > 6) ?
            sec = values.value.substring(4, 6) :
            sec = '00';
          (values.value.length > 7) ?
            frame = values.value.substr(-2) :
            frame = '00';

          (frame > 24) ? frame = 24 : frame = frame;
          (sec > 59) ? sec = 59 : sec = sec;
          (m > 59) ? m = 59 : m =m;
          values.value = h+m+sec+frame
        }
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format='##:##:##:##'
      placeholder='00:00:00:00'
      isNumericString
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};


// -------------------------------------------
// create form for insert edit channel
// -------------------------------------------
const creatEditForm = (data, func, classes) => {
  D.log('creatEditForm', data, func)
  return (
    <>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          required
          id='channelName'
          name='channelName'
          label='Channel Name'
          fullWidth
          error={!data.channelNameValid}
          autoComplete='channelName'
          value={data.channelName || ''}
          onChange={e => func.channel(e)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          id='channelCode'
          name='channelCode'
          label='Channel Code'
          fullWidth
          error={!data.channelCodeValid}
          autoComplete='channelCode'
          value={data.channelCode || ''}
          onChange={e => func.channel(e)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
      <FormControl className={classes.select}>
        <InputLabel shrink id="demo-simple-select-placeholder-label-label">
          Channel Type *
        </InputLabel>
        <Select
          labelId="channelType-label"
          id="channelType"
          name="channelType"
          value={data.channelType || ''}
          error={!data.channelTypeValid}
          onChange={e => func.channel(e)}
          required
          >
          <MenuItem value='Linear'>Linear</MenuItem>
          <MenuItem value='DT'>DT</MenuItem>
        </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
          <TextField
            required
            id='channelStartTime'
            name='channelStartTime'
            label='Start time'
            fullWidth
            error={!data.channelStartTimeValid}
            autoComplete='channelStartTime'
            value={data.channelStartTime || ''}
            onChange={e => func.channel(e)}
            InputProps={{
              inputComponent: NumberFormatCustom ,
            }}
          />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          id='channelEndTime'
          name='channelEndTime'
          label='End Time'
          fullWidth
          error={!data.channelEndTimeValid}
          autoComplete='channelEndTime'
          value={data.channelEndTime || ''}
          onChange={e => func.channel(e)}
          InputProps={{
            inputComponent: NumberFormatCustom ,
          }}
        />
      </Grid>

      <Grid item xs={12} sm={8}>
      </Grid>
      <Grid item xs={12} sm={4}>
        <ButtonGroup size="small" aria-label="Form control butttons" className={classes.buttons}>
          <Button
            variant='contained'
            color='primary'
            onClick={e => func.saveChannel(e)}
            className={classes.button}
            >
            Save
          </Button>
          <Button
            variant='contained'

            onClick={() => func.cancelChannelEdit()}
            className={classes.button}
          >
          Cancel
          </Button>
        </ButtonGroup>
      </Grid>
    </Grid>

    </>
  )
}

// -------------------------------------------
// create channel list and control
// -------------------------------------------
const createList = (channels, func, classes) => {
  D.log('createList', channels)
  return (
    <div>
    { channels.channels.map(item => {
      D.log(item)
      return (
        <React.Fragment key={'i_' + item._vid}>
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt={ item.channelName } src="/static/images/avatar/1.jpg" />
        </ListItemAvatar>
        <ListItemText
          primary={ item.channelName }
          secondary={
            <React.Fragment key={'il_' + item._vid}>
              <Typography
                component="span"
                variant="body2"
                className={classes.inline}
                color="textPrimary"
              >
                Code:&nbsp;
              </Typography>
              { item.channelCode }
              &nbsp;
              <Typography
                component="span"
                variant="body2"
                className={classes.inline}
                color="textPrimary"
              >
                Type:&nbsp;
              </Typography>
              { item.channelType }
              &nbsp;
              <Typography
                component="span"
                variant="body2"
                className={classes.inline}
                color="textPrimary"
              >
                Start Time:&nbsp;
              </Typography>
              { D.timeToFormatedString(item.channelStartTime) }
              &nbsp;
              <Typography
                component="span"
                variant="body2"
                className={classes.inline}
                color="textPrimary"
              >
                End Time:&nbsp;
              </Typography>
              { D.timeToFormatedString(item.channelEndTime) }

            </React.Fragment>
          }
        />
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-label="comments"
            id={item._id}
            onClick={() => func.channelToEdit(item._id)}>
            <CommentIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Divider variant="inset" component="li" />
      </React.Fragment>
    )
    })
  }
  </div>
  )
}

export default function ChannelsList(param) {
  const classes = useStyles();

  const data = param.data.Data.data
  const func = param.data.func
  const editChannel = param.data.Data.editChannel
  D.log('ChannelsList',param.data)
  return (
    <>
    <Grid item xs={12}>
      { (editChannel.isEdit || editChannel.isInsert) ? creatEditForm(editChannel, func, classes) : createInsertBtn(func, classes)}
      <Divider component="li" />
      <List className={classes.root}>
        {createList(data, func, classes)}
      </List>
      <Button
        variant='contained'
        color='primary'
        onClick={e => func.save(e)}
        className={classes.button}
      >
      Save
      </Button>
    </Grid>
    </>
  );
}
