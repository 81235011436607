import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Copyright from './Copyright';

const useStyles = makeStyles((theme) => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3)
    }
  }
}))


export default function TooSmall (props) {

  const classes = useStyles()

  return (
    <>
      <main className={classes.layout}>
        <Paper className={classes.paper}>
        <p>Screen resolution is too small.</p> 
        <p>Please use a bigger screen or try the landscape mode.</p>
        </Paper>
        <Box pt={4}>
          <Copyright />
        </Box>
      </main>
    </>
  )
}
