import React, { Component } from 'react';
import MainMenu from './../stateless/MainLayout/MainMenu';
import D from './../data/dbConst';

class MainLayout extends Component {
  constructor (props) {
    super(props)
    this.state = {
      name: '',
      password: '',
      errorMsg: '',
      smallMenu: false,
    };

    this.schedule = this.schedule.bind(this);
    this.programs = this.programs.bind(this);
    this.rawPlanner = this.rawPlanner.bind(this);
    this.users = this.users.bind(this);
    this.settings = this.settings.bind(this);
    this.logoutApp = this.logoutApp.bind(this);
    this.setMenuOpen = this.setMenuOpen.bind(this);
  }

  logoutApp () {
    D.logout();
  }

  settings () {
    D.log('settings')
    window.location.replace('/settings')
  }

  schedule () {
    D.log('settings')
    window.location.replace('/schedule')
  }

  users () {
    D.log('user')
    window.location.replace('/user')
  }

  rawPlanner () {
    D.log('Rawplanner')
    window.location.replace('/rawplanner')
  }

  programs () {
    D.log('Programs')
    window.location.replace('/programs')
  }

  setMenuOpen(param) {
    localStorage.setItem('menuClose', param)
    this.setState({smallMenu:param})
  }

  render () {
    const param = {
      schedule: this.schedule,
      programs: this.programs,
      rawPlanner: this.rawPlanner,
      users: this.users,
      settings: this.settings,
      logoutApp: this.logoutApp,
      smallMenu: this.state.smallMenu,
      setMenuOpen: this.setMenuOpen,
    }

    return (
      <div>
        <MainMenu params={param} />
      </div>
    )
  }
}

export default MainLayout
